.detail_new {
  min-height: 100vh;
  width: 100%;
  font-family: PingFangSC-Semibold, PingFang SC;
  background: #ffffff; }
  .detail_new .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2.66667vw; }
    .detail_new .header .back {
      width: 5.33333vw;
      height: 5.33333vw;
      background: url("/help_new/icon_back1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 2.66667vw; }
    .detail_new .header .title {
      font-size: 4.8vw;
      color: #111E36;
      font-weight: 500; }
  .detail_new .content {
    padding: 4.26667vw; }
    .detail_new .content .title {
      font-size: 5.86667vw;
      font-weight: 500;
      color: #111E36; }
    .detail_new .content .time {
      color: #878C9C;
      font-size: 4vw; }
    .detail_new .content .image {
      width: 100%;
      height: 48vw;
      padding: 2.66667vw 0; }
    .detail_new .content .msg {
      font-size: 4.53333vw;
      color: #111E36;
      font-family: PingFangSC-Regular, PingFang SC; }
  .detail_new .iKonw {
    width: 91.46667vw;
    height: 11.73333vw;
    background: #2467E2;
    border-radius: 0.8vw;
    margin: 5.33333vw auto;
    line-height: 11.73333vw;
    text-align: center;
    font-size: 4.8vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF; }
