body {
  background-color: #fff; }

.wrapper {
  min-height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-image: url("/help_new/head_bg_new.png");
  background-position: top center;
  background-size: 100% auto; }

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 8vw 4.26667vw 0;
  height: 58.66667vw; }
  .header :global .icon-back {
    align-self: flex-start; }
  .header :global .title {
    margin-top: 6.4vw;
    color: #fff;
    font-size: 6.4vw;
    font-weight: 600;
    line-height: 1.2; }
  .header :global .desc {
    margin-bottom: 9.6vw;
    color: rgba(255, 255, 255, 0.7);
    font-size: 6.4vw;
    font-weight: 600; }
  .header .title {
    color: #121e36;
    font-size: 4.8vw;
    font-weight: 600; }

.scroll_container {
  margin-top: -4.26667vw;
  padding-top: 3.2vw;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  border-top-left-radius: 4.26667vw;
  border-top-right-radius: 4.26667vw;
  overflow: hidden; }
  .scroll_container :global .am-tabs-pane-wrap-active {
    margin-bottom: calc(env(safe-area-inset-bottom) + 21.33333vw); }
  .scroll_container :global .am-tabs-default-bar-tab::after {
    content: "";
    position: absolute;
    background-color: #000;
    display: block;
    z-index: 1;
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  .scroll_container :global .am-tabs-default-bar-tab-active {
    color: #000;
    font-weight: 600; }
  .scroll_container :global .am-tabs-default-bar-underline {
    border-color: #000; }
  .scroll_container .item_header {
    padding: 0 2.13333vw 0 4.26667vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 12.26667vw;
    box-sizing: border-box; }
    .scroll_container .item_header .item_header_title {
      color: #121e36;
      font-size: 4.26667vw; }
    .scroll_container .item_header .item_header_arrow {
      width: 6.4vw;
      height: 6.4vw;
      transform: rotate(90deg);
      background: url("/help_new/icon_arrow_up.png") no-repeat;
      background-size: 100%;
      transition: transform 0.3s; }
      .scroll_container .item_header .item_header_arrow:global(.down) {
        transform: rotate(0deg); }
  .scroll_container .item_content_child {
    padding: 3.2vw 4.26667vw; }
    .scroll_container .item_content_child .item_content_child_title {
      color: #121e36;
      font-size: 4.26667vw;
      font-weight: 400;
      line-height: 4.26667vw; }

.footer {
  width: 91.46667vw;
  margin: 8vw 4.26667vw 4.26667vw;
  height: 12.26667vw;
  background: var(---LightGradient, linear-gradient(90deg, #dd484d 0%, #ed8a82 100%));
  border-radius: 12.26667vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 4.8vw;
  position: fixed;
  bottom: 0; }
  .footer .icon_kefu {
    width: 6.93333vw;
    height: 5.86667vw;
    background: url("/help_new/icon_kefu.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 2.66667vw; }

.empty {
  height: 26.66667vw;
  color: #999;
  line-height: 26.66667vw;
  text-align: center; }
