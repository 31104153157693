.about_us {
  font-size: 3.73333vw; }
  .about_us h3 {
    font-size: 6.93333vw;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 5.33333vw; }

.about p {
  margin-bottom: 5.33333vw; }

.strength {
  padding: 8vw;
  min-height: 96vw;
  background: url("/about/s.png") no-repeat center center;
  background-size: cover; }
  .strength h3 {
    color: #fff;
    margin-bottom: 5.33333vw; }

.icon_1,
.icon_2,
.icon_3,
.icon_4 {
  display: block;
  margin: 5.33333vw auto 0;
  width: 32vw;
  height: 16vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

.icon_1 {
  background-image: url("/about/s_1.png"); }

.icon_2 {
  background-image: url("/about/s_2.png"); }

.icon_3 {
  background-image: url("/about/s_3.png"); }

.icon_4 {
  background-image: url("/about/s_4.png"); }

.i_1,
.i_2,
.i_3,
.i_4,
.i_5,
.i_6 {
  display: block;
  width: 17.33333vw;
  height: 17.33333vw;
  flex-basis: 17.33333vw;
  flex-shrink: 0;
  margin-right: 5.33333vw;
  background-size: 70%;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3); }

.i_1 {
  background-image: url("/about/i_1.png"); }

.i_2 {
  background-image: url("/about/i_2.png"); }

.i_3 {
  background-image: url("/about/i_3.png"); }

.i_4 {
  background-image: url("/about/i_4.png"); }

.i_5 {
  background-image: url("/about/i_5.png"); }

.i_6 {
  background-image: url("/about/i_6.png"); }

.strength_item {
  background: #fff;
  flex-grow: 1;
  margin: 1.33333vw;
  text-align: center;
  font-size: 4.26667vw;
  padding: 5.33333vw 0;
  font-weight: bold; }

.banner {
  height: 42.66667vw;
  background: url("/about/banner.png") no-repeat center center;
  background-size: cover;
  color: #fff;
  font-size: 8vw;
  font-weight: bold;
  line-height: 1.2;
  white-space: nowrap; }

.slogan {
  height: 37.33333vw;
  background: url("/about/b.png") no-repeat center center;
  background-size: cover;
  color: #fff;
  font-size: 4.26667vw;
  box-sizing: border-box;
  padding: 8vw;
  line-height: 1.5; }
  .slogan h3 {
    text-align: left;
    color: #fff;
    margin-bottom: 2.66667vw;
    font-size: 5.86667vw; }

.slide > div {
  margin: 5.33333vw 0; }
