.download {
  padding: 16vw 5.33333vw 2.66667vw;
  text-align: center;
  box-sizing: border-box; }
  .download h2 {
    font-family: PingFangSC-Semibold;
    font-size: 8.8vw;
    color: #10468a;
    font-weight: bold;
    line-height: 1.2; }
  .download p {
    font-family: PingFangSC-Regular; }
  .download h3 {
    font-family: PingFangSC-Semibold;
    font-size: 4.8vw;
    color: #333;
    font-weight: bold; }

.summary {
  font-size: 5.6vw;
  color: #10468a; }

.ic_logo {
  width: 32vw;
  height: 32vw;
  position: relative;
  margin: 18.66667vw auto 18.66667vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

.animate {
  width: 26.66667vw;
  height: 26.66667vw;
  position: relative;
  margin: 18.66667vw auto 18.66667vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%; }
  .animate:after {
    display: block;
    content: "";
    width: 140%;
    height: 140%;
    border-radius: 50%;
    background: url("/download/circle.png") no-repeat center center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0);
    background-size: 96%;
    animation-name: makeRotate;
    animation-duration: 3s;
    animation-delay: 1s; }

@keyframes makeRotate {
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.link {
  color: #2470ec;
  text-decoration: underline;
  padding: 0 1.33333vw; }

.download_wrapper {
  margin-top: 10.66667vw;
  margin-bottom: 16vw; }
  .download_wrapper > p {
    margin: 2.66667vw; }
  .download_wrapper .box {
    justify-content: space-around; }
    .download_wrapper .box .download_btn_ios {
      display: block;
      background: #134387;
      line-height: 13.33333vw;
      color: #fff;
      font-size: 3.73333vw;
      white-space: nowrap;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.25s;
      border-radius: 1.06667vw;
      line-height: 1.5;
      padding: 4vw 8vw;
      position: relative;
      overflow: hidden;
      text-decoration: none; }
      .download_wrapper .box .download_btn_ios :global(.label) {
        background-image: url(/download/label.png);
        background-size: 100% auto;
        width: 19.2vw;
        height: 14.4vw;
        position: absolute;
        top: 0;
        right: 0; }
      .download_wrapper .box .download_btn_ios .ios_icon {
        display: inline-block;
        background: url("/download/ios.svg") center no-repeat;
        width: 5.33333vw;
        height: 5.33333vw;
        background-size: 100% 100%;
        margin-right: 1.06667vw; }
      .download_wrapper .box .download_btn_ios.loading {
        opacity: 0.8;
        transition: 0.25s; }
        .download_wrapper .box .download_btn_ios.loading span {
          display: block;
          visibility: visible;
          opacity: 1;
          animation: loadingState 1.2s infinite;
          -webkit-animation: loadingState 1.2s infinite; }
    .download_wrapper .box .download_btn_appStore {
      background: #000; }
  .download_wrapper .download_btn_page {
    display: block;
    background: transparent;
    color: #1779ff;
    font-family: PingFangSC-Regular;
    text-decoration: underline;
    padding: 4vw; }
    .download_wrapper .download_btn_page :global(.gestrue_icon) {
      display: inline-block;
      vertical-align: text-top;
      background: url(/download/gestrue_icon.png) no-repeat center center;
      background-size: contain;
      width: 5.06667vw;
      height: 4vw;
      margin-left: 1.33333vw; }
  .download_wrapper .download_btn_and {
    display: block;
    background: #134387;
    line-height: 13.33333vw;
    color: #fff;
    font-size: 3.73333vw;
    white-space: nowrap;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s;
    border-radius: 1.06667vw;
    line-height: 1.5;
    padding: 4vw 2.66667vw;
    position: relative;
    overflow: hidden; }
    .download_wrapper .download_btn_and :global(.label) {
      background-image: url(/download/label.png);
      background-size: 100% auto;
      width: 19.2vw;
      height: 14.4vw;
      position: absolute;
      top: 0;
      right: 0; }
    .download_wrapper .download_btn_and.loading {
      opacity: 0.8;
      transition: 0.25s; }
      .download_wrapper .download_btn_and.loading span {
        display: block;
        visibility: visible;
        opacity: 1;
        animation: loadingState 1.2s infinite;
        -webkit-animation: loadingState 1.2s infinite; }

.otherDownlod {
  font-size: 3.2vw;
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
  line-height: 1;
  margin: 0;
  background: #000 url(/download/ios.svg) no-repeat 1.6vw center;
  background-size: 4.26667vw;
  text-decoration: none;
  padding: 1.6vw 1.6vw 1.6vw 6.66667vw;
  text-align: left;
  border-radius: 1.06667vw;
  box-sizing: border-box; }
  .otherDownlod small {
    font-size: 3.2vw;
    display: block;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 1.33333vw; }

@keyframes loadingState {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes loadingState {
  100% {
    transform: rotate(360deg); } }

.strength {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.73333vw;
  color: #8f8f8f; }
  .strength span {
    display: inline-block;
    padding: 0 2.66667vw;
    border-right: 1px solid #d8d8d8;
    line-height: 1; }
    .strength span:last-child {
      border-right: none; }

.hidden {
  visibility: hidden;
  opacity: 0; }

.shadow,
.ucShadow {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.8);
  background-position: right 2.66667vw top 2.66667vw;
  background-repeat: no-repeat;
  background-size: 50%; }

.shadow {
  background-image: url("/tips.png"); }
  .shadow.android {
    background-image: url("/tips_android.png"); }

.ucShadow {
  background-image: url("/tips-uc.png"); }

.install {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
  text-align: left;
  line-height: 1.5;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }
  .install img {
    display: block;
    margin: 0 auto;
    min-height: 12vw;
    position: relative;
    margin: 2.66667vw 0;
    left: -4vw; }
  .install p {
    margin-top: 4vw;
    line-height: 2;
    padding: 1.33333vw 0;
    font-size: 4vw; }
  .install > div {
    background: #fff;
    padding: 4vw;
    position: relative;
    width: 100%;
    box-sizing: border-box; }

.step_detail {
  padding-bottom: 8vw;
  background: #fff;
  padding: 5.33333vw; }
  .step_detail p {
    line-height: 1.5;
    padding: 2.13333vw 0;
    font-size: 3.73333vw; }
  .step_detail img {
    display: block;
    margin: 0 auto;
    margin-bottom: 5.33333vw; }
  .step_detail h5 {
    font-size: 5.33333vw;
    font-weight: bold;
    margin-bottom: 4vw; }

.confirm {
  background: #000;
  display: block;
  color: #fff;
  line-height: 11.73333vw;
  text-align: center;
  font-weight: bold; }
  .confirm i {
    display: inline-block;
    vertical-align: middle;
    width: 6.93333vw;
    height: 6.93333vw;
    background: url(/download/app_store.png) no-repeat center center;
    background-size: contain;
    margin-right: 2.66667vw; }

.close {
  position: absolute;
  right: 2.66667vw;
  top: 2.66667vw; }

.fixed_bottom {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.9);
  padding: 2.66667vw !important;
  box-sizing: border-box;
  box-shadow: 0 0 1.33333vw rgba(0, 0, 0, 0.15); }

.icon_seting {
  display: inline-block;
  width: 6.4vw;
  height: 6.4vw;
  vertical-align: middle;
  background: url("/download/t_s.png") no-repeat center center;
  background-size: contain; }

.account_list {
  white-space: nowrap;
  margin: 2.66667vw 0 5.33333vw;
  background: #f1f8fc;
  border: 1px solid #c2dff3;
  color: #1882d4;
  width: 100%;
  text-align: left;
  border-collapse: collapse; }
  .account_list p {
    padding: 1.33333vw 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .account_list span {
    padding: 0 2.66667vw;
    line-height: 1.5;
    color: #1882d4;
    border-radius: 1.06667vw;
    border: 1px solid #1882d4; }
  .account_list li {
    padding: 2.66667vw;
    position: relative; }
    .account_list li:not(:last-child) {
      border-bottom: 1px solid #c2dff3; }
    .account_list li:after {
      display: block;
      content: "";
      width: 1px;
      height: 60%;
      background: #c2dff3;
      position: absolute;
      right: 21.33333vw;
      top: 50%;
      transform: translateY(-50%); }

.list_icon {
  width: 6.93333vw;
  height: 6.93333vw;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background: #2470ec linear-gradient(90deg, #2470ec, #fff);
  color: #fff;
  text-align: center;
  line-height: 6.93333vw; }

.rules {
  margin-bottom: 16vw;
  line-height: 2;
  color: #1882d4; }

div > .local_jnBg {
  background: url(/download/detail_bg_jn.png) no-repeat center top;
  background-size: contain; }

.local_bg {
  height: 398.66667vw;
  background: url(/download/detail_bg.png) no-repeat center top;
  background-size: contain;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding-top: 2.66667vw;
  font-size: 3.73333vw; }
  .local_bg h2 {
    margin-top: 26.66667vw;
    font-size: 6.4vw;
    font-weight: bold; }
  .local_bg a:nth-child(1) {
    margin-top: 8.8vw;
    background: #fff;
    color: #10468a; }
  .local_bg a:nth-child(2) {
    background: #ffffff00;
    border: 1px solid #ffffff;
    color: #fff; }
  .local_bg a {
    margin: 2.66667vw auto;
    display: block;
    width: 48vw;
    line-height: 11.73333vw;
    font-size: 4.26667vw;
    font-weight: bold; }
  .local_bg :global(.info) {
    font-size: 3.2vw;
    opacity: 0.6;
    line-height: 4.8vw; }
  .local_bg :global(.local_bg_body) {
    margin-top: 94.4vw;
    color: #000; }
    .local_bg :global(.local_bg_body) h3 {
      font-size: 4.26667vw;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600; }
    .local_bg :global(.local_bg_body) p {
      margin-top: 4.26667vw;
      font-size: 3.73333vw;
      color: #4f4f4f;
      letter-spacing: 0.3px;
      text-align: center;
      line-height: 5.86667vw;
      font-weight: 400; }
    .local_bg :global(.local_bg_body) b {
      color: #0d70fa;
      font-weight: bold; }
    .local_bg :global(.local_bg_body) img {
      display: block;
      margin: 11.2vw auto 5.33333vw auto; }

.local_banner {
  height: 146.13333vw;
  background: url(/download/bg.png) no-repeat center top;
  background-size: contain;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding-top: 8vw;
  font-size: 3.73333vw; }
  .local_banner a {
    display: block;
    width: 48vw;
    margin: 2.66667vw auto;
    color: #075be8;
    background: #fff;
    border-radius: 1.06667vw;
    line-height: 11.73333vw;
    font-size: 4.26667vw;
    font-weight: bold; }
  .local_banner h2 {
    font-size: 6.4vw;
    font-weight: bold; }
    .local_banner h2:before {
      display: block;
      content: "";
      width: 18.66667vw;
      height: 18.66667vw;
      background: url(/download/logo.png) no-repeat center center;
      background-size: contain;
      margin: 0 auto 2.66667vw; }

.local_body {
  padding: 8vw;
  font-size: 4.26667vw;
  color: #031b4e;
  background: #fff; }
  .local_body img {
    display: block;
    margin: 5.33333vw auto;
    max-width: 100%; }
  .local_body h3 {
    font-size: 4.8vw;
    text-align: center;
    margin-bottom: 5.33333vw;
    font-weight: bold; }
  .local_body b {
    color: #0343b4;
    font-weight: bold; }
    .local_body b.symbol {
      vertical-align: middle;
      padding-right: 3.73333vw;
      background: url(/download/arrow_right.png) no-repeat right center;
      background-size: 3.2vw; }

.zrjy,
.xhjy,
.nhjy {
  background: url(/download/zrjy_bg.png) no-repeat 0 0/cover; }
  .zrjy h2,
  .xhjy h2,
  .nhjy h2 {
    font-family: PingFangSC-Semibold !important;
    font-size: 6.13333vw !important;
    color: #c59e65 !important;
    font-weight: 600 !important;
    margin: 23.33333vw 0 11.6vw !important; }
  .zrjy .logo,
  .xhjy .logo,
  .nhjy .logo {
    margin: 0 auto 11.33333vw;
    width: 34.66667vw;
    text-align: center; }
    .zrjy .logo img,
    .xhjy .logo img,
    .nhjy .logo img {
      width: 100%; }
  .zrjy .strength,
  .xhjy .strength,
  .nhjy .strength {
    font-family: PingFangSC-Regular;
    font-size: 4.8vw;
    color: #000000;
    font-weight: 400;
    margin-bottom: 14vw; }
    .zrjy .strength span,
    .xhjy .strength span,
    .nhjy .strength span {
      display: inline-block;
      padding: 0 1.33333vw;
      border-right: 0.53333vw solid black;
      line-height: 1; }
      .zrjy .strength span:last-child,
      .xhjy .strength span:last-child,
      .nhjy .strength span:last-child {
        border-right: none; }
  .zrjy .download_btn,
  .xhjy .download_btn,
  .nhjy .download_btn {
    display: inline-block;
    width: 89.33333vw;
    height: 13.06667vw;
    background: #c09961;
    border-radius: 1.6vw;
    line-height: 13.06667vw;
    font-family: PingFangSC-Medium;
    font-size: 4.53333vw;
    color: #ffffff;
    text-align: center;
    font-weight: 500; }
  .zrjy .tips,
  .xhjy .tips,
  .nhjy .tips {
    font-family: PingFangSC-Medium;
    font-size: 3.73333vw;
    color: #000000;
    text-align: center;
    font-weight: 500; }

.xhjy {
  background: url(/download/xhjy_bg.png) no-repeat 0 0/cover; }

.nhjy {
  background: none; }
  .nhjy h2 {
    color: #ba9b69 !important; }
  .nhjy .download_btn {
    background: #ba9b69; }

.hkjy,
.rsjy {
  background: url(/download/hkjy_bg.png) no-repeat 0 0/cover; }
  .hkjy .logo,
  .rsjy .logo {
    margin: 0 auto 4.13333vw;
    text-align: center;
    width: 57.33333vw;
    height: 15.33333vw; }
    .hkjy .logo img,
    .rsjy .logo img {
      width: 100%; }
  .hkjy h2,
  .rsjy h2 {
    font-family: PingFangSC-Regular;
    font-size: 4.53333vw;
    color: #000000;
    font-weight: 400; }
  .hkjy .sample,
  .rsjy .sample {
    width: 100%;
    margin: -3.2vw auto 21.33333vw; }
  .hkjy .download_wrapper,
  .rsjy .download_wrapper {
    margin-top: 0;
    margin-bottom: 28vw; }
  .hkjy .download_btn,
  .rsjy .download_btn {
    display: inline-block;
    width: 80.53333vw;
    height: 13.06667vw;
    border-radius: 1.6vw;
    background: #444444;
    line-height: 13.06667vw;
    font-family: PingFangSC-Medium;
    font-size: 4.53333vw;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    margin: auto; }
  .hkjy .tips,
  .rsjy .tips {
    font-family: PingFangSC-Medium;
    font-size: 3.73333vw;
    color: #797979;
    text-align: center;
    font-weight: 500; }

.ljajy {
  height: 216.53333vw;
  padding: 29.33333vw 0 9.6vw;
  text-align: center;
  background: url(/download/ljajy_bg.png) no-repeat 0 0/cover; }
  .ljajy .logo {
    margin: 0 auto 5.33333vw;
    text-align: center;
    width: 56vw;
    height: 10.66667vw; }
    .ljajy .logo img {
      width: 100%; }
  .ljajy .main_slogan {
    width: 84vw;
    margin: 0 auto 10.66667vw;
    position: relative;
    font-family: PingFangSC-Regular;
    font-size: 4.8vw;
    color: #000000;
    font-weight: 400;
    line-height: 1; }
    .ljajy .main_slogan::before, .ljajy .main_slogan::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 10.66667vw;
      height: 0.53333vw;
      border-radius: 1px; }
    .ljajy .main_slogan::before {
      left: 0;
      background: linear-gradient(270deg, #200d0d 0%, rgba(183, 183, 183, 0.4) 100%); }
    .ljajy .main_slogan::after {
      right: 0;
      background: linear-gradient(90deg, #200d0d 0%, rgba(183, 183, 183, 0.4) 100%); }
  .ljajy .sample {
    width: 100%; }
  .ljajy .download_wrapper {
    width: 91.46667vw;
    margin: 17.06667vw auto 29.33333vw; }
  .ljajy .download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 13.86667vw;
    border-radius: 1.6vw;
    background: #c21a0b;
    line-height: 13.86667vw;
    font-family: PingFangSC-Medium;
    font-size: 4.8vw;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    margin: auto; }
  .ljajy .ios_icon {
    background: url("/download/ios.svg") center no-repeat;
    width: 5.33333vw;
    height: 5.33333vw;
    background-size: 100% 100%;
    margin-right: 1.06667vw; }
  .ljajy .tips {
    font-family: PingFangSC-Medium;
    font-size: 3.2vw;
    color: #200d0d;
    text-align: center;
    font-weight: 500; }
