.axjy {
  height: 216.53333vw;
  padding: 30.66667vw 0 0;
  text-align: center;
  background: url(/download/axjy/bg.png) no-repeat 0 0/cover; }
  .axjy .sample {
    width: 100vw;
    height: 80vw;
    margin: 0 auto 5.33333vw; }
  .axjy .logo {
    width: 84vw;
    height: 25.33333vw;
    margin: 0 auto 22.66667vw; }
  .axjy .download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 91.46667vw;
    height: 13.86667vw;
    margin: 0 auto 27.2vw;
    border-radius: 1.86667vw;
    background: #3f2d1d;
    font-family: PingFangSC-Medium;
    font-size: 4.26667vw;
    color: #ffffff;
    text-align: center;
    font-weight: 500; }
  .axjy .ios_icon {
    background: url("/download/ios.svg") center no-repeat;
    width: 5.33333vw;
    height: 5.33333vw;
    background-size: 100% 100%;
    margin-right: 1.06667vw; }
  .axjy .tips {
    font-family: PingFangSC-Medium;
    font-size: 3.2vw;
    color: #3f2d1d;
    text-align: center;
    font-weight: 500;
    line-height: 1; }
