body {
  background: #ffffff; }

.section {
  min-height: 100vh;
  position: relative; }

.banner {
  position: relative;
  font-family: PingFangSC-Medium, PingFang SC;
  height: 45.33333vw;
  background: url("/help_new/help_new_bg.png") no-repeat;
  background-size: 100% 100%;
  font-size: 7.73333vw;
  color: #FFFFFF;
  box-sizing: border-box;
  font-weight: 500;
  padding: 10.66667vw 5.33333vw; }
  .banner .back {
    width: 5.33333vw;
    height: 5.33333vw;
    background: url("/help_new/icon_back.png") no-repeat;
    background-size: 100% 100%; }
  .banner .guide {
    left: 8vw;
    bottom: 6.4vw;
    position: absolute; }

.askList {
  padding: 0 5.33333vw 26.66667vw; }
  .askList .askList_item {
    border-bottom: 1px solid #E6EBF5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 13.33333vw; }
    .askList .askList_item .askList_title {
      font-size: 4vw;
      color: #121E36; }
    .askList .askList_item .askList_arrow {
      width: 4vw;
      height: 4vw;
      background: url("/help_new/icon_arrow_right.png") no-repeat;
      background-size: 100%;
      margin-right: 2.66667vw; }

.footer {
  position: fixed;
  bottom: 0;
  width: 91.46667vw;
  margin: 8vw  4.26667vw 4.26667vw;
  height: 11.73333vw;
  background: #2064DA;
  border-radius: 1.06667vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 4.8vw; }
  .footer .icon_kefu {
    width: 6.93333vw;
    height: 5.86667vw;
    background: url("/help_new/icon_kefu.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 2.66667vw; }
