.xhzx {
  height: 216.53333vw;
  padding: 40.53333vw 0 10.66667vw;
  text-align: center;
  background: url(/download/xhzx/bg2.png) no-repeat 0 0/cover, url(/download/xhzx/bg1.png) no-repeat 0 0/cover; }
  .xhzx .sample {
    width: 88.8vw;
    height: 84.8vw;
    margin: 0 auto 10.66667vw; }
  .xhzx .logo {
    width: 54.4vw;
    height: 13.86667vw;
    margin: 0 auto 25.6vw; }
  .xhzx .download_wrapper {
    width: 76.53333vw;
    height: 11.73333vw;
    margin: 0 auto 15.46667vw; }
  .xhzx .download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10.66667vw;
    background: #ff6900;
    line-height: 11.2vw;
    font-family: PingFangSC-Medium;
    font-size: 4.26667vw;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    margin: auto; }
  .xhzx .ios_icon {
    background: url("/download/ios.svg") center no-repeat;
    width: 5.33333vw;
    height: 5.33333vw;
    background-size: 100% 100%;
    margin-right: 1.06667vw; }
  .xhzx .tips {
    font-family: PingFangSC-Medium;
    font-size: 3.2vw;
    color: #222;
    text-align: center;
    font-weight: 500;
    line-height: 1; }
