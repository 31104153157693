.sjjy {
  height: 216.53333vw;
  padding: 40vw 0 10.66667vw;
  text-align: center;
  background: url(/download/sjjy/bg.png) no-repeat 0 0/cover; }
  .sjjy .sample {
    width: 88.8vw;
    height: 85.06667vw;
    margin: 0 auto 5.86667vw; }
  .sjjy .logo {
    width: 54.93333vw;
    height: 7.2vw;
    margin: 0 auto 25.6vw; }
  .sjjy .download_wrapper {
    width: 91.46667vw;
    height: 11.2vw;
    margin: 0 auto 26.13333vw; }
  .sjjy .download_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10.66667vw;
    background: #261f1f;
    line-height: 11.2vw;
    font-family: PingFangSC-Medium;
    font-size: 3.73333vw;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    margin: auto; }
  .sjjy .ios_icon {
    background: url("/download/ios.svg") center no-repeat;
    width: 5.33333vw;
    height: 5.33333vw;
    background-size: 100% 100%;
    margin-right: 1.06667vw; }
  .sjjy .tips {
    font-family: PingFangSC-Medium;
    font-size: 3.2vw;
    color: #261f1f;
    text-align: center;
    font-weight: 500;
    line-height: 1; }
